// extracted by mini-css-extract-plugin
export var frontView = "index-module--frontView--2SKWt";
export var websiteEntryFirst = "index-module--websiteEntryFirst--lbJ7X";
export var websiteEntrySecond = "index-module--websiteEntrySecond--oXLiB";
export var navigation = "index-module--navigation--3cCZO";
export var darkBackground = "index-module--darkBackground--3RYp_";
export var headerPadding = "index-module--headerPadding--3BRSD";
export var projectsHolder = "index-module--projectsHolder--1Ly77";
export var skills = "index-module--skills--wT6jf";
export var aboutContainer = "index-module--aboutContainer--gkFWr";
export var skillList = "index-module--skillList--iMjc6";
export var mainWebsiteEntry = "index-module--mainWebsiteEntry--3Gm1Q";
export var aboutMeParagraph = "index-module--aboutMeParagraph--bITc-";
export var leftSidedSkills = "index-module--leftSidedSkills--ow4BX";
export var rightSidedSkills = "index-module--rightSidedSkills--QpkLZ";