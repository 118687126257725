// extracted by mini-css-extract-plugin
export var iconedParagraph = "reusable-module--iconedParagraph--2QjQ_";
export var mainIcon = "reusable-module--mainIcon--2YGeP";
export var yellowLineHeader = "reusable-module--yellowLineHeader--3c247";
export var projectContainer = "reusable-module--projectContainer--38SO-";
export var imageHolder = "reusable-module--imageHolder--2-EHo";
export var projectImage = "reusable-module--projectImage--1mmqd";
export var projectView = "reusable-module--projectView--2PgR1";
export var projectDescription = "reusable-module--projectDescription--3WV99";
export var projectModal = "reusable-module--projectModal--3hEEO";
export var projectModalSideBackground = "reusable-module--projectModalSideBackground--2bqJj";
export var projectModalData = "reusable-module--projectModalData--1ZUDW";
export var fadeIn = "reusable-module--fadeIn--27ec4";
export var skillSet = "reusable-module--skillSet--CV8NS";
export var aboutMeParagraph = "reusable-module--aboutMeParagraph--1CXD-";
export var firstDiv = "reusable-module--firstDiv--1GFqk";
export var secondDiv = "reusable-module--secondDiv--2GN1T";
export var thirdDiv = "reusable-module--thirdDiv--1BNVn";